import React from 'react'
import { Layout, Parallax, } from '@components/layout'
import { Breadcrumbs } from '@components/shared'

import {
	page__wrapper,  page__content
} from './styles/page.module.scss'


const Faq = ({ pageContext }) => {
    const { page } = pageContext
    return (
        <Layout
			animation={false}
			seo={pageContext.page.seo}
			siteMetaData={pageContext.page.siteMetaData}
		>
				<Parallax height={300}
					image={page.featuredImage}
					title={'FAQ - najczęściej zadawane pytania'}
				>
				<h4>Faq</h4>
				</Parallax>
						<div className={page__wrapper}>
						<div
						className={page__content}>
						<Breadcrumbs
						elements={[
							{
								label: page.title,
								url: `/${page.slug}/`,
							},
						]}
					/>
                        <h3>test test faq</h3>
						</div>
						</div>
        </Layout>
    )
}

export default Faq